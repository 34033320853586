<script>
  export let isActive = false;
  export let isFirst = false;
  export let isHover = false;
  export let getOptionLabel = undefined;
  export let item = undefined;
  export let filterText = '';

  let itemClasses = '';

  $: {
    const classes = [];
    if (isActive) { classes.push('active'); }
    if (isFirst) { classes.push('first'); }
    if (isHover) { classes.push('hover'); }
    if (item.isGroupHeader) { classes.push('groupHeader'); }
    if (item.isGroupItem) { classes.push('groupItem'); }
    itemClasses = classes.join(' ');
  }
</script>

<style>.item {
  cursor: default;
  height: var(--height, 42px);
  line-height: var(--height, 42px);
  padding: var(--itemPadding, 0 20px);
  color: var(--itemColor, inherit);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.groupHeader {
  text-transform: var(--groupTitleTextTransform, uppercase);
}

.groupItem {
  padding-left: var(--groupItemPaddingLeft, 40px);
}

.item:active {
  background: var(--itemActiveBackground, #b9daff);
}

.item.active {
  background: var(--itemIsActiveBG, #007aff);
  color: var(--itemIsActiveColor, #fff);
}

.item.first {
  border-radius: var(--itemFirstBorderRadius, 4px 4px 0 0);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vZGVfbW9kdWxlcy9zdmVsdGUtc2VsZWN0L3NyYy9JdGVtLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGVBQWU7RUFDZiwyQkFBMkI7RUFDM0IsZ0NBQWdDO0VBQ2hDLG1DQUFtQztFQUNuQyxnQ0FBZ0M7RUFDaEMsdUJBQXVCO0VBQ3ZCLGdCQUFnQjtFQUNoQixtQkFBbUI7QUFDckI7O0FBRUE7RUFDRSx5REFBeUQ7QUFDM0Q7O0FBRUE7RUFDRSwrQ0FBK0M7QUFDakQ7O0FBRUE7RUFDRSxnREFBZ0Q7QUFDbEQ7O0FBRUE7RUFDRSwwQ0FBMEM7RUFDMUMscUNBQXFDO0FBQ3ZDOztBQUVBO0VBQ0Usd0RBQXdEO0FBQzFEIiwiZmlsZSI6Im5vZGVfbW9kdWxlcy9zdmVsdGUtc2VsZWN0L3NyYy9JdGVtLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLml0ZW0ge1xuICBjdXJzb3I6IGRlZmF1bHQ7XG4gIGhlaWdodDogdmFyKC0taGVpZ2h0LCA0MnB4KTtcbiAgbGluZS1oZWlnaHQ6IHZhcigtLWhlaWdodCwgNDJweCk7XG4gIHBhZGRpbmc6IHZhcigtLWl0ZW1QYWRkaW5nLCAwIDIwcHgpO1xuICBjb2xvcjogdmFyKC0taXRlbUNvbG9yLCBpbmhlcml0KTtcbiAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XG4gIG92ZXJmbG93OiBoaWRkZW47XG4gIHdoaXRlLXNwYWNlOiBub3dyYXA7XG59XG5cbi5ncm91cEhlYWRlciB7XG4gIHRleHQtdHJhbnNmb3JtOiB2YXIoLS1ncm91cFRpdGxlVGV4dFRyYW5zZm9ybSwgdXBwZXJjYXNlKTtcbn1cblxuLmdyb3VwSXRlbSB7XG4gIHBhZGRpbmctbGVmdDogdmFyKC0tZ3JvdXBJdGVtUGFkZGluZ0xlZnQsIDQwcHgpO1xufVxuXG4uaXRlbTphY3RpdmUge1xuICBiYWNrZ3JvdW5kOiB2YXIoLS1pdGVtQWN0aXZlQmFja2dyb3VuZCwgI2I5ZGFmZik7XG59XG5cbi5pdGVtLmFjdGl2ZSB7XG4gIGJhY2tncm91bmQ6IHZhcigtLWl0ZW1Jc0FjdGl2ZUJHLCAjMDA3YWZmKTtcbiAgY29sb3I6IHZhcigtLWl0ZW1Jc0FjdGl2ZUNvbG9yLCAjZmZmKTtcbn1cblxuLml0ZW0uZmlyc3Qge1xuICBib3JkZXItcmFkaXVzOiB2YXIoLS1pdGVtRmlyc3RCb3JkZXJSYWRpdXMsIDRweCA0cHggMCAwKTtcbn1cblxuLml0ZW0uaG92ZXI6bm90KC5hY3RpdmUpIHtcbiAgYmFja2dyb3VuZDogdmFyKC0taXRlbUhvdmVyQkcsICNlN2YyZmYpO1xufVxuIl19 */</style>



<div class="item {itemClasses}">
  {@html getOptionLabel(item, filterText)}
</div>
