<script>
	import { onMount, getContext } from 'svelte';
	import { key } from './rightMenu.js';
	
	export let isDisabled = false;
	export let text = '';
	export let menutype = '';
	
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();	
	
	const { dispatchClick } = getContext(key);
	
	const handleClick = e => {
		if (isDisabled) return;
		dispatch('click');
		dispatchClick(text);
	}
</script>
<style>div {
  padding: 4px 15px;
  cursor: default;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

div.sub {
  padding-left: 30px;
}

div.parent:hover, div.sub:hover {
  background: #0002;
}

div.disabled {
  color: #0006;
}

div.disabled:hover {
  background: white;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9SaWdodE1lbnVPcHRpb24uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0MsaUJBQWlCO0VBQ2pCLGVBQWU7RUFDZixlQUFlO0VBQ2YsYUFBYTtFQUNiLG1CQUFtQjtFQUNuQixhQUFhO0FBQ2Q7O0FBQ0E7RUFDQyxrQkFBa0I7QUFDbkI7O0FBQ0E7RUFDQyxpQkFBaUI7QUFDbEI7O0FBQ0E7RUFDQyxZQUFZO0FBQ2I7O0FBQ0E7RUFDQyxpQkFBaUI7QUFDbEIiLCJmaWxlIjoic3JjL1JpZ2h0TWVudU9wdGlvbi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbmRpdiB7XG5cdHBhZGRpbmc6IDRweCAxNXB4O1xuXHRjdXJzb3I6IGRlZmF1bHQ7XG5cdGZvbnQtc2l6ZTogMTRweDtcblx0ZGlzcGxheTogZmxleDtcblx0YWxpZ24taXRlbXM6IGNlbnRlcjtcblx0Z3JpZC1nYXA6IDVweDtcbn1cbmRpdi5zdWIge1xuXHRwYWRkaW5nLWxlZnQ6IDMwcHg7XG59XG5kaXYucGFyZW50OmhvdmVyLCBkaXYuc3ViOmhvdmVyIHtcblx0YmFja2dyb3VuZDogIzAwMDI7XG59XG5kaXYuZGlzYWJsZWQge1xuXHRjb2xvcjogIzAwMDY7XG59XG5kaXYuZGlzYWJsZWQ6aG92ZXIge1xuXHRiYWNrZ3JvdW5kOiB3aGl0ZTtcbn1cbiJdfQ== */</style>
<div class="{menutype}"
  class:disabled={isDisabled}
  on:click={handleClick}
>
	{#if text}
		{text}
	{:else}
		<slot />
	{/if}
</div>
