<div class="carousel">
	<div class="slides" bind:this={siema}>
		<slot></slot>
	</div>
	{#if controls}
	  <button class="left" on:click={left} use:resetInterval={autoplay} aria-label="left">
		  <slot name="left-control"></slot>
	  </button>
	  <button class="right" on:click={right} use:resetInterval={autoplay} aria-label="right">
		  <slot name="right-control"></slot>
	  </button>
	{/if}
    {#if dots}
	<ul>
		{#each {length: totalDots} as _, i}
		<li on:click={() => go(i*currentPerPage)} class={isDotActive(currentIndex, i) ? "active" : ""}></li>
		{/each}
	</ul>
    {/if}
</div>

<style>.carousel {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
}

button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  z-index: 50;
  margin-top: -20px;
  border: none;
  background-color: transparent;
}

button:focus {
  outline: none;
}

.left {
  left: 2vw;
}

.right {
  right: 2vw;
}

ul {
  list-style-type: none;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -30px;
  padding: 0;
}

ul li {
  margin: 6px;
  border-radius: 100%;
  background-color: rgba(255,255,255,0.5);
  height: 8px;
  width: 8px;
}

ul li:hover {
  background-color: rgba(255,255,255,0.85);
}

ul li.active {
  background-color: rgba(255,255,255,1);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9DYXJvdXNlbC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDQyxrQkFBa0I7RUFDbEIsV0FBVztFQUNYLHVCQUF1QjtFQUN2QixtQkFBbUI7QUFDcEI7O0FBRUE7RUFDQyxrQkFBa0I7RUFDbEIsV0FBVztFQUNYLFlBQVk7RUFDWixRQUFRO0VBQ1IsV0FBVztFQUNYLGlCQUFpQjtFQUNqQixZQUFZO0VBQ1osNkJBQTZCO0FBQzlCOztBQUNDO0VBQ0UsYUFBYTtBQUNmOztBQUVEO0VBQ0MsU0FBUztBQUNWOztBQUVBO0VBQ0MsVUFBVTtBQUNYOztBQUNBO0VBQ0MscUJBQXFCO0VBQ3JCLGtCQUFrQjtFQUNsQixhQUFhO0VBQ2IsdUJBQXVCO0VBQ3ZCLFdBQVc7RUFDWCxpQkFBaUI7RUFDakIsVUFBVTtBQUNYOztBQUNBO0VBQ0MsV0FBVztFQUNYLG1CQUFtQjtFQUNuQix1Q0FBdUM7RUFDdkMsV0FBVztFQUNYLFVBQVU7QUFDWDs7QUFDQTtFQUNDLHdDQUF3QztBQUN6Qzs7QUFDQTtFQUNDLHFDQUFxQztBQUN0QyIsImZpbGUiOiJzcmMvQ2Fyb3VzZWwuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4uY2Fyb3VzZWwge1xuXHRwb3NpdGlvbjogcmVsYXRpdmU7XG5cdHdpZHRoOiAxMDAlO1xuXHRqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcblx0YWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cblxuYnV0dG9uIHtcblx0cG9zaXRpb246IGFic29sdXRlO1xuXHR3aWR0aDogNDBweDtcblx0aGVpZ2h0OiA0MHB4O1xuXHR0b3A6IDUwJTtcblx0ei1pbmRleDogNTA7XG5cdG1hcmdpbi10b3A6IC0yMHB4O1xuXHRib3JkZXI6IG5vbmU7XG5cdGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xufVxuIGJ1dHRvbjpmb2N1cyB7XG4gICBvdXRsaW5lOiBub25lO1xuIH1cblxuLmxlZnQge1xuXHRsZWZ0OiAydnc7XG59XG5cbi5yaWdodCB7XG5cdHJpZ2h0OiAydnc7XG59XG51bCB7XG5cdGxpc3Qtc3R5bGUtdHlwZTogbm9uZTtcblx0cG9zaXRpb246IGFic29sdXRlO1xuXHRkaXNwbGF5OiBmbGV4O1xuXHRqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcblx0d2lkdGg6IDEwMCU7XG5cdG1hcmdpbi10b3A6IC0zMHB4O1xuXHRwYWRkaW5nOiAwO1xufVxudWwgbGkge1xuXHRtYXJnaW46IDZweDtcblx0Ym9yZGVyLXJhZGl1czogMTAwJTtcblx0YmFja2dyb3VuZC1jb2xvcjogcmdiYSgyNTUsMjU1LDI1NSwwLjUpO1xuXHRoZWlnaHQ6IDhweDtcblx0d2lkdGg6IDhweDtcbn1cbnVsIGxpOmhvdmVyIHtcblx0YmFja2dyb3VuZC1jb2xvcjogcmdiYSgyNTUsMjU1LDI1NSwwLjg1KTtcbn1cbnVsIGxpLmFjdGl2ZSB7XG5cdGJhY2tncm91bmQtY29sb3I6IHJnYmEoMjU1LDI1NSwyNTUsMSk7XG59XG4iXX0= */</style>

<script>
	import Siema from 'siema'
	import { onMount, createEventDispatcher } from 'svelte'
	
	export let perPage = 3
	export let loop = true
	export let autoplay = 0
	export let duration = 200
	export let easing = 'ease-out'
	export let startIndex = 0
	export let draggable = true
	export let multipleDrag = true	
	export let dots = true	
	export let controls = true
	export let threshold = 20
	export let rtl = false
	let currentIndex = startIndex;
	
	let siema
	let controller
	let timer
	const dispatch = createEventDispatcher()
	
	$: pips = controller ? controller.innerElements : []
	$: currentPerPage = controller ? controller.perPage : perPage
	$: totalDots = controller ? Math.ceil(controller.innerElements.length / currentPerPage) : []
	
	onMount(() => {
		perPage = typeof perPage === 'object' ? perPage : Number(perPage);
		controller = new Siema({
			selector: siema,
			perPage:  window.innerWidth > 767 ? perPage : 1, //typeof perPage === 'object' ? perPage : Number(perPage),
			loop,
  			duration,
  			easing,
  			startIndex,
  			draggable,
 			multipleDrag,
  			threshold,
  			rtl,
			onChange: handleChange
		})
		
		if(autoplay) {
			timer = setInterval(right, autoplay);
		}
		return () => {
			autoplay && clearInterval(timer)
			controller.destroy()
		}
	})
	
	export function isDotActive (currentIndex, dotIndex) {
        if (currentIndex < 0) currentIndex = pips.length + currentIndex;
        return currentIndex >= dotIndex*currentPerPage && currentIndex < (dotIndex*currentPerPage)+currentPerPage
    }
	
	export function left () {
		controller.prev()
	}
	
	export function right () {
		controller.next()
	}
	
	export function go (index) {
		controller.goTo(index)
	}
	
	export function pause() {
		clearInterval(timer);
	}
	
	export function resume() {
		if (autoplay) {
			timer = setInterval(right, autoplay);
		}
	} 
	
	function handleChange (event) {
		currentIndex = controller.currentSlide
		dispatch('change', {
			currentSlide: controller.currentSlide,
			slideCount: controller.innerElements.length
		} )
	}
	
	function resetInterval(node, condition) {
		function handleReset(event) {
			pause();
			resume();
		}
		
		if(condition) {
			node.addEventListener('click', handleReset);
		}
		
		return {
		  destroy() {
			  node.removeEventListener('click', handleReset);
		  }
	  }
  }
</script>