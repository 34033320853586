<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { pannable } from "./utils/pannable.js";
    export let display;
    const dispatch = createEventDispatcher();
    async function render() {
    }
    onMount(render);
    function onHide(){
        dispatch("hide");
    }
    function onReload(){
        location.reload();
    }
</script>
  
<style>.left-menu-wrap{
  height: 100vh;
  width: 200px;
  background: #fff;
  z-index: 99;
  box-shadow: 5px 0px 10px 0px grey;
  transition: 0.5s;
  padding: 0 15px;
}

.left-menu-wrap.pending{
  left: -300px;
}

.item{
  margin-top: 30px;
}

.item img{
  margin-right: 10px;
  width: 30px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9MZWZ0TWVudS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDSSxhQUFhO0VBQ2IsWUFBWTtFQUNaLGdCQUFnQjtFQUNoQixXQUFXO0VBQ1gsaUNBQWlDO0VBQ2pDLGdCQUFnQjtFQUNoQixlQUFlO0FBQ25COztBQUNBO0VBQ0ksWUFBWTtBQUNoQjs7QUFDQTtFQUNJLGdCQUFnQjtBQUNwQjs7QUFDQTtFQUNJLGtCQUFrQjtFQUNsQixXQUFXO0FBQ2YiLCJmaWxlIjoic3JjL0xlZnRNZW51LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmxlZnQtbWVudS13cmFwe1xuICAgIGhlaWdodDogMTAwdmg7XG4gICAgd2lkdGg6IDIwMHB4O1xuICAgIGJhY2tncm91bmQ6ICNmZmY7XG4gICAgei1pbmRleDogOTk7XG4gICAgYm94LXNoYWRvdzogNXB4IDBweCAxMHB4IDBweCBncmV5O1xuICAgIHRyYW5zaXRpb246IDAuNXM7XG4gICAgcGFkZGluZzogMCAxNXB4O1xufVxuLmxlZnQtbWVudS13cmFwLnBlbmRpbmd7XG4gICAgbGVmdDogLTMwMHB4O1xufVxuLml0ZW17XG4gICAgbWFyZ2luLXRvcDogMzBweDsgICAgICAgICAgICBcbn1cbi5pdGVtIGltZ3tcbiAgICBtYXJnaW4tcmlnaHQ6IDEwcHg7XG4gICAgd2lkdGg6IDMwcHg7XG59XG4iXX0= */</style>
  
<svelte:options immutable={true} />
    <div class="left-menu-wrap fixed left-0 top-0 select-none" class:pending={!display}>
    <div class="w-full">
        <div class="w-full flex items-center" style="justify-content: flex-end; font-size: 25px;">
            <span on:click={onHide} class="px-1 cursor-pointer">×</span>
        </div>
        <div class="w-full flex items-center" style="margin-bottom: 50px;">
            <img class="" src="/qix_gradient_logo.png" />
        </div>
        <div class="w-full flex items-center item cursor-pointer" on:click={onReload}>
            <img src="qix_sign_logo.svg">Sign
        </div>
        <div class="w-full flex items-center item cursor-pointer">
            <img src="qix_chat_logo.png">Chat
        </div>
        <div class="w-full flex items-center item cursor-pointer">
            <img src="folder.png">App
        </div>
        <div class="w-full flex items-center item cursor-pointer">
            <img src="cloud_api_logo.png">Api
        </div>
        <div class="w-full flex items-center item cursor-pointer">
            <img src="account_icon.png">Account
        </div>
        <div class="w-full flex items-center item cursor-pointer">
            <img src="help.png">Help
        </div>
    </div>
</div>
  