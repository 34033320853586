<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { clickOutside } from "./utils/clickOutside.js";
  export let items;
  export let type;
  export let placeholder;
  let searchKey = "";
  let displayDropdown = false;
  const dispatch = createEventDispatcher();
  async function render() {}
  function onSelect(item) {
    // console.log("item", item);
    searchKey = item.label;
    dispatch("select", { item });
  }
  function onDelete() {
    dispatch("delete");
  }
  function displayDropdownFunc() {
    displayDropdown = !displayDropdown;
  }
  function hideDropdownFunc() {
    displayDropdown = false;
  }
  onMount(render);
</script>

<div class="select-menu-wrap">
  <div>
    <input
      {placeholder}
      type="text"
      class="flex-grow bg-transparent py-1 px-2 w-full"
      style="border: 1px solid;border-radius: 5px;"
      bind:value={searchKey}
      on:click={displayDropdownFunc}
      use:clickOutside
      on:click_outside={hideDropdownFunc}
    />
  </div>
  <div class="overflow-y-auto py-1 select-menu" class:hidden={!displayDropdown}>
    {#each items as item}
      {#if type == "email"}
        {#if item.email != ""}
          {#if searchKey == "" || item.email
              .toLowerCase()
              .includes(searchKey.toLowerCase())}
            <div
              class="flex items-center rounded p-2 hover:bg-gray-100 dark:hover:bg-gray-600"
              on:click={() => onSelect(item)}
            >
              <span>{item.email}</span><img src="/flags/{item.lang}.png" />
            </div>
          {/if}
        {/if}
      {:else if searchKey == "" || item.label
          .toLowerCase()
          .includes(searchKey.toLowerCase())}
        <div
          class="flex items-center rounded p-2 hover:bg-gray-100 dark:hover:bg-gray-600"
          on:click={() => onSelect(item)}
        >
          <span>{item.label}</span><img src="/flags/{item.lang}.png" />
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>.select-menu > div {
  justify-content: space-between;
  cursor: pointer;
}

.select-menu img {
  width: 20px;
}

.select-menu {
  max-height: 12rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9TZWxlY3Rib3guc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsOEJBQThCO0VBQzlCLGVBQWU7QUFDakI7O0FBQ0E7RUFDRSxXQUFXO0FBQ2I7O0FBQ0E7RUFDRSxpQkFBaUI7QUFDbkIiLCJmaWxlIjoic3JjL1NlbGVjdGJveC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5zZWxlY3QtbWVudSA+IGRpdiB7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuLnNlbGVjdC1tZW51IGltZyB7XG4gIHdpZHRoOiAyMHB4O1xufVxuLnNlbGVjdC1tZW51IHtcbiAgbWF4LWhlaWdodDogMTJyZW07XG59XG4iXX0= */</style>
