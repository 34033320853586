<script>
	import { onMount, setContext, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';
	import { key } from './rightMenu.js';

	export let x;
	export let y;
	let wy = 0;
	
	// whenever x and y is changed, restrict box to be within bounds
	$: (() => {
		if (!menuEl) return;
		
		const rect = menuEl.getBoundingClientRect();
		//x = Math.min(window.innerWidth - rect.width, x);
		if (x > window.innerWidth - rect.width) x -= rect.width;
		//console.log(window.innerHeight, rect.height, y, wy);
		if (y - wy > window.innerHeight - rect.height) y -= rect.height;
	})(x, y);
	
	const dispatch = createEventDispatcher();	
	
	setContext(key, {
		dispatchClick: () => dispatch('click')
	});
	
	let menuEl;
	function onPageClick(e) {
		if (e.target === menuEl || menuEl.contains(e.target)) return;
		dispatch('clickoutside');
	}
</script>

<style>div {
  position: absolute;
  display: grid;
  border: 1px solid #0003;
  box-shadow: 2px 2px 5px 0px #0002;
  background: white;
  z-index: 9;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9SaWdodE1lbnUuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0Msa0JBQWtCO0VBQ2xCLGFBQWE7RUFDYix1QkFBdUI7RUFDdkIsaUNBQWlDO0VBQ2pDLGlCQUFpQjtFQUNYLFVBQVU7QUFDakIiLCJmaWxlIjoic3JjL1JpZ2h0TWVudS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbmRpdiB7XG5cdHBvc2l0aW9uOiBhYnNvbHV0ZTtcblx0ZGlzcGxheTogZ3JpZDtcblx0Ym9yZGVyOiAxcHggc29saWQgIzAwMDM7XG5cdGJveC1zaGFkb3c6IDJweCAycHggNXB4IDBweCAjMDAwMjtcblx0YmFja2dyb3VuZDogd2hpdGU7XG4gICAgICAgei1pbmRleDogOTtcbn1cbiJdfQ== */</style>

<svelte:window on:click={onPageClick} bind:scrollY={wy} />

<div transition:fade={{ duration: 100 }} bind:this={menuEl} class="py-1 px-2" style="top: {y}px; left: {x}px;">
	<slot />
</div>